.media-container {
    margin-top: 140px;
}

.media-container .hero {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
}

.media-container .info {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #494a4c;
    gap: 12px;
    margin: 25px 0 0;
}

.media-container .carousel-area {
    margin-top: 60px;
}

.carousel-area {
    position: relative;
    flex: 0 0 58%;
    max-width: 100%;
    padding: 40px;
}



.slick-list, .slick-slider {
    position: relative;
    display: block;
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-slider .slick-list, .slick-slider .slick-track {
    transform: translateZ(0);
    height: 450px;
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list, .slick-slider {
    position: relative;
    display: block;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 13680px;
    opacity: 1;
    transform: translate3d(-4560px, 0px, 0px);
}

.slick-track:after, .slick-track:before {
    display: table;
    content: "";
}

.slick-initialized .slick-slide {
    display: block;
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}

.slick-cloned {
    width: 380px;
}

.media-container .item-wrapper {
    padding: 0 10px;
    width: 100%;
    display: inline-block;
    margin: 0 60px;
}

.media-container .carousel-area .item {
    padding: 25px;
    background-color: #ffecd5;
    border-radius: 20px;
}

.media-container .carousel-area .item .label, .media-container .carousel-area .item .username {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.slick-slide img {
    display: block;
}

.media-container .carousel-area .item .username {
    font-size: 12px;
    font-weight: 600;
}

.media-container .carousel-area .item .username img {
    height: 20px;
}

.media-container .carousel-area .item .username span {
    color: #494a4c;
    font-weight: 400;
}

.media-container .carousel-area .item .message-area {
    padding-left: 50px;
}

.media-container .carousel-area .item .message-area img {
    display: block;
    width: 100%;
    border-radius: 16px;
}

.media-container .carousel-area .item .actions ul {
    padding-left: 60px;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    margin: 15px 0 0;
}

.media-container .carousel-area .item .actions ul li span {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    color: #757575;
    cursor: pointer;
    font-size: 12px;
}

.slick-current {
    width: 380px;
}

.slick-dots {
    display: none;
}

.media-container .carousel-area .slick-dots li {
    margin: 0 5px;
}

.slick-dots li {
    margin: 0 7px;
    background-color: transparent;
}

.media-container .carousel-area .slick-dots {
    position: static;
    margin-top: 25px;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.media-container .carousel-area .slick-dots li button {
    box-shadow: unset !important;
    border: unset !important;
}

.slick-dots li button {
    width: 6px !important;
    height: 6px !important;
    background-color: #494a4c !important;
    border: 4px solid #fff;
    box-shadow: 0 0 0 3px #494a4c !important;
    cursor: pointer;
    transition: all .3s linear;
    border-radius: 100%;
    opacity: .4;
}

.slick-dots li.slick-active button {
    width: 6px !important;
    height: 6px !important;
    background-color: #494a4c !important;
    border: 4px solid #fff;
    box-shadow: 0 0 0 3px #494a4c !important;
    cursor: default;
    transition: all .3s linear;
    opacity: 1;
}

.slick-dots li button:focus, .slick-dots li button:hover {
    outline: none;
}

.slick-dots li button:before {
    color: transparent;
}

.slick-dots li.slick-active button:before {
    color: transparent;
}

.hotdog-img {
    position: absolute;
    max-width: 250px;
    right: 100px;
    top: -80px;
    transform: rotate(15deg);
}

.p-relative {
    position: relative;
}

.custom-slider {
    width: 100%; /* Adjust as needed */
    height: 680px; /* Set the height to 800px */
    position: relative;
    flex: 0 0 58%;
    max-width: 100%;
    padding: 40px;
  
  }
  
  .custom-slider .slick-slider,
  .custom-slider .slick-list,
  .custom-slider .slick-track,
  .custom-slider .slide {
    height: 100% !important; /* Make sure the slider and slides take up the full height */
  }
  
  .custom-slider .slide {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccc; /* Optional: Background color for visualization */
  }
  

/* Responsive Styles */
@media (max-width: 768px) {
    .media-container .item-wrapper {
        padding: 0 10px;
        width: 400px;
        max-width: 400px;
        display: inline-block;
        margin: 0 60px;
    }

}