.header-logo-container{
    width: '550px';
    max-width: 550px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .header-logo-container{
        width: '100%';
        margin: '0px auto';
    }

}